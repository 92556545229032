<template>
  <v-app>
    <v-main>
      <vHeader/>
      <router-view></router-view>
      <vFooter/>
    </v-main>
  </v-app>
</template>

<script>
import vHeader from './components/v-header'
import vFooter from './components/v-footer'
export default {
  name: 'App',

  components: {
    vHeader, vFooter
  }
};
</script>
<style>
  @import "./assets/style.css";
</style>
