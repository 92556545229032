<template>
    <v-row>
        <v-dialog
                v-model="dialog"
                persistent
                max-width="320"
        >
            <!--        <template v-slot:activator="{ on, attrs }">-->
            <!--            <v-btn-->
            <!--                    color="primary"-->
            <!--                    dark-->
            <!--                    v-bind="attrs"-->
            <!--                    v-on="on"-->
            <!--            >-->
            <!--                Open Dialog-->
            <!--            </v-btn>-->
            <!--        </template>-->
            <v-card>
                <v-card-title class="headline">
                    {{title}}
                </v-card-title>
                <v-card-text>{{subtitle}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="green darken-1"
                            text
                            @click="closeDialog"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
  export default {
    name: "Dialog",
    props: {
      dialog: Boolean,
      title: String,
      subtitle: String,
      success: Boolean,
    },
    emits: [
      'closeDialog'
    ],
    data () {
      return {
      }
    },
    methods: {
      closeDialog () {
        this.$emit('closeDialog')
      }
    }
  }
</script>

<style scoped>

</style>