<template>
  <header class="v-header">
      <div class="d-flex justify-space-between align-center v-header__container" style="color: white">
        <slot v-if="window >= 1000">
          <div>АНО НЕЗАВИСИМЫЙ АРБИТР</div>
          <div class="d-flex">
            <v-tabs
                    v-model="tab"
                    flat
                    dark
                    background-color="rgba(0,0,0,0)"
            >
              <v-tab><router-link to="/">Главная</router-link></v-tab>
              <v-tab><router-link to="/about">О компании</router-link></v-tab>
              <v-tab><router-link to="/comments">Отзывы</router-link></v-tab>
              <v-tab><router-link to="/contacts">Контакты</router-link></v-tab>
            </v-tabs>
          </div>
          <div class="d-flex justify-lg-space-between align-center" style="min-width: 200px;">
            <img src="../assets/img/icons/phone.svg" alt="">
            <a href="tel:+77654352948" style="font-size: 18px; color: white">+7 765 435 29 48</a>
          </div>
        </slot>
        <v-menu v-if="window <=750"
                bottom
                left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
            >
              <v-list-item-title><router-link to="/">Главная</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
            >
              <v-list-item-title><router-link to="/about">О компании</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
            >
              <v-list-item-title><router-link to="/comments">Отзывы</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
            >
              <v-list-item-title><router-link to="/contacts">Контакты</router-link></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

  </header>
</template>

<script>
  export default {
    name: 'Header',
    data () {
      return {
        tab: false,
      }
    },
    computed: {
      window: () => {
        return window.innerWidth
      }
    }
  }
</script>
<style lang="scss">
  .v-header {
    position: absolute;
    width: 100%;
    .v-header__container {
      margin: 20px auto;
      width: 90%;
      a {color: white}
    }
  }
</style>