<template>
  <div class="v-home" v-scroll:#scroll-target="onScroll">
    <section class="v-home__head">
      <div class="v-home__head-title">
        Знаем как сделать так,<br>чтобы вы не получили срок
        <p>Защитим по УК 264.1</p>
        <v-btn
                color="rgb(255 247 0)"
                elevation="2"
                href="#ya1"
        >Получить консультацию</v-btn>
        <div class="v-home__head-img">
          <img src="../assets/img/klipartz.png" alt="">
        </div>
      </div>
    </section>
    <section class="effects">
      <h2 style="margin: 60px 0; font-size: 35px" class="d-flex justify-center">Последствия уголовного дела</h2>
      <div class="d-flex flex-wrap" style="min-height: 581px">
        <v-card
                height="fit-content"
                class="mx-auto ma-3"
                max-width="300"
        >
          <v-img
                  src="../assets/img/unnamed1.jpg"
                  height="300px"
          ></v-img>
          <v-card-title>
            На 1000 водителей...
          </v-card-title>
          <v-card-actions>
            <v-btn
                    color="grey"
                    text
                    @click="show1 = !show1"
            >
              Подробнее...
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    @click="show1 = !show1"
            >
              <v-icon>{{ show1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show1">
              <v-divider></v-divider>
              <v-card-text>
                Статистика конкретна - более 10% водителей получают реальный тюремный срок. Отягчающим обстоятельством при назначении срока служат даже обычные штрафы на 500 рублей за превышение скорости              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
        <v-card
                height="fit-content"
                class="mx-auto ma-3"
                max-width="300"
        >
          <v-img
                  src="../assets/img/visa.jpg"
                  height="300px"
          ></v-img>
          <v-card-title>
            Отказ в визах...
          </v-card-title>
          <v-card-actions>
            <v-btn
                    color="grey"
                    text
                    @click="show2 = !show2"
            >
              Подробнее...
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    @click="show2 = !show2"
            >
              <v-icon>{{ show2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show2">
              <v-divider></v-divider>
              <v-card-text>
                В большинство европейских стран людям, имеющим судимость, получить визу просто невозможно и это накладывает дополнительные ограничения на жизнь людей. Статья судимости не имеет значения
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
        <v-card
                margin="10px 0"
                height="fit-content"
                class="mx-auto ma-3"
                max-width="300"
        >
          <v-img
                  src="../assets/img/rabota-400x300.jpg"
                  height="300px"
          ></v-img>
          <v-card-title>
            Отказ трудойстройства...
          </v-card-title>
          <v-card-actions>
            <v-btn
                    color="grey"
                    text
                    @click="show3 = !show3"
            >
              Подробнее...
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    @click="show3 = !show3"
            >
              <v-icon>{{ show3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show3">
              <v-divider></v-divider>
              <v-card-text>
                Большинство работодателей отказывают в приеме на работу лиц, имеющих уголовное прошлое. В наше время доступного распространения информации этот аргумент зачастую является определяющим - отказы в трудоустройстве получают даже высококлассные специалисты.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
        <v-card
                height="fit-content"
                class="mx-auto ma-3"
                max-width="300"
        >
          <v-img
                  src="../assets/img/credit.jpg"
                  height="300px"
          ></v-img>
          <v-card-title>
            Отказ в кредитах...
          </v-card-title>
          <v-card-actions>
            <v-btn
                    color="grey"
                    text
                    @click="show4 = !show4"
            >
              Подробнее...
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    @click="show4 = !show4"
            >
              <v-icon>{{ show4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show4">
              <v-divider></v-divider>
              <v-card-text>
                Скоринговые системы банков имеют фильтр на привлечение к уголовной ответственности. Даже если в итоге человек отделался штрафов он считается судимым и финансовые учреждения отказывают в кредитах одинаково насильникам и тем, кто попался за повторное управление в состоянии опьянения
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
    </section>
    <section class="consult">
      <div class="consult__title" id="ya1">Получить бесплатную консультацию по 264 УФ РК</div>
      <div class="v-home__forms">
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="d-flex justify-space-around v-home__forms-wrap"
        >
          <v-text-field v-model="form.name" dark :rules="rules" class="v-home__forms-input" placeholder="Ваше имя"></v-text-field>
          <v-text-field v-model="form.email" dark class="v-home__forms-input" placeholder="Почта"></v-text-field>
          <v-text-field v-model="form.phone" dark :rules="rules1" class="v-home__forms-input" placeholder="Телефон" ></v-text-field>
        </v-form>
        <div class="btn-wrapper">
          <v-alert
                  v-if="!valid"
                  type="error"
                  class="btn-wrapper-err-mess"
          >Правильно заполните поля</v-alert>
          <v-btn
                  :disabled="!valid"
                  color="rgb(255 247 0)"
                  class="mr-4 v-home__forms-btn"
                  @click="send"
                  dark
          >
            Отправить
          </v-btn>
        </div>
      </div>
      <div></div>
    </section>
    <section class="advokats">
      <div class="advokats__title">ВОТ ЧТО СДЕЛАЮТ ДЛЯ ВАС НАШИ АДВОКАТЫ И ЮРИСТЫ</div>
      <div class="advokats__wrapper d-flex justify-space-between flex-wrap">
        <div class="advokats__container ma-3">
          <div class="advokats__title">ОПРАВДАЮТ ВАС</div>
          <v-btn text color="white" class="advokats__btn">Получить консультацию</v-btn>
        </div>
        <div class="advokats__container ma-3">
          <div class="advokats__title">УСЛОВНЫЙ
            СРОК</div>
          <v-btn text color="white" class="advokats__btn">Получить консультацию</v-btn>
        </div>
        <div class="advokats__container ma-3">
          <div class="advokats__title">ОБЯЗАТЕЛЬНЫЕ РАБОТЫ<br>
            ВМЕСТО ТЮРЬМЫ</div>
          <v-btn text color="white" class="advokats__btn">Получить консультацию</v-btn>
        </div>
        <div class="advokats__container ma-3">
          <div class="advokats__title">ОТСРОЧКА ИСПОЛНЕНИЯ<br>
            И АМНИСТИЯ</div>
          <v-btn text color="white" class="advokats__btn">Получить консультацию</v-btn>
        </div>
      </div>
      <div class="advokats__case">
        <div class="case-title">Берем в работу только дела с шансами<br>не менее 50%</div>
        <div class="case__wrapper">
          <div class="case-container"><img src="../assets/img/icons/ok.svg" alt=""><p>Отменим первое нарушение даже если сроки пропущены</p></div>
          <div class="case-container"><img src="../assets/img/icons/ok.svg" alt=""><p>Работаем вплоть до Верховного Суда</p></div>
          <div class="case-container"><img src="../assets/img/icons/ok.svg" alt=""><p>Работаем во всех регионах РФ</p></div>
          <div class="case-container"><img src="../assets/img/icons/ok.svg" alt=""><p>Работаем с гособвинением и бесплатными адвокатами, которых Вам предоставит государство</p></div>
        </div>
      </div>
    </section>
    <section class="know">
      <div class="know-wrap">
        <div class="know__title">Узнайте как</div>
        <div class="know__wrapper d-flex">
          <div class="know__left">
            <div class="know__left-container">Говорим правду о шансах на бесплатной консультации</div>
            <div class="know__left-container">Рассказываем как и что<br>будем делать ничего не скрывая</div>
            <div class="know__left-container">Вы все знаете заранее</div>
          </div>
          <div class="know__right">
            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class=""
                    id="ya2"
            >
              <v-text-field v-model="form.name" dark :rules="rules" class="v-home__forms-input" placeholder="Ваше имя"></v-text-field>
              <v-text-field v-model="form.email" dark class="v-home__forms-input" placeholder="Почта"></v-text-field>
              <v-text-field v-model="form.phone" dark :rules="rules1" class="v-home__forms-input" placeholder="Телефон" ></v-text-field>
              <v-textarea
                      dark
                      outlined
                      name="input-7-4"
                      label="Опишите вашу проблему"
                      v-model="form.detail"
              ></v-textarea>
            </v-form>
            <v-btn text dark class="know__right-btn" @click="send" :disabled="!valid">Узнать</v-btn>
          </div>
        </div>
      </div>
    </section>
    <Dialog
            :dialog="dialog.isDialog"
            :success:="dialog.success"
            :title="dialog.title"
            :subtitle="dialog.subtitle"
            @closeDialog="closeDialog"
            class="popup"
    />
  </div>
</template>

<script>
  import axios from 'axios'
  import Dialog from './dialog'
  export default {
    name: 'Home',
    components: { Dialog },
    data: () => ({
      form: {
        name: null,
        email: null,
        phone: null,
        detail: null,
      },
      dialog: {
        isDialog: null,
        title: '',
        subtitle: '',
        success: null,
      },
      offsetTop: 0,
      show1: true,
      show2: true,
      show3: true,
      show4: true,
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value && value.length >= 3) || 'Не менее 3 символов',
      ],
      rules1: [
        value => !!value || 'Обязательное поле',
        value => (typeof value === "string") || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр',
      ],
      valid: true,
    }),
    methods: {
      onScroll (e) {
        this.offsetTop = e.target.scrollTop
      },
      send (e) {
        if (this.valid) {
          console.log(e)
          const formData = new FormData()
          formData.append('crm', '11')
          formData.append('pipe', '22')
          formData.append('contact[name]', this.form.name)
          formData.append('contact[199]', this.form.phone)
          formData.append('contact[200]', this.form.email)
          // formData.append('lead[214]', 'ЕБСА')
          // formData.append('note', 'Заявка с ЕБСА по статье ' + this.numArticle + '<br>' + this.result.join())
          // для mychance.avtourist.info
          formData.append('lead[214]', '264uk')
          formData.append('note', 'Заявка с 264uk <br>' + 'Детали: ' + this.form.detail ? this.form.detail : 'детали не указаны') // удаление запятых из this.result
          axios.post('https://urist.v-avtoservice.com/api/import-lead',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
          ).then(res => {
            if (res.data.status === 'success') {
              this.dialog.title = 'Отправлено!'
              this.dialog.subtitle = 'Ваше заявка успешно отправлена! Наш специлист свяжется с вам в ближайшие минуты'
              this.dialog.isDialog = true
              this.dialog.success = true
            } else {
              this.dialog.title = 'Ошибка!'
              this.dialog.subtitle = 'Обновите страницу и попробуйте снова'
              this.dialog.isDialog = false
              this.dialog.success = false
            }
          })
        }
      },
      closeDialog () {
        this.dialog.isDialog = false
      }
    }
  }
</script>
<style lang="scss">
  .v-home {
    padding: 5% 5% 0 5%;
    color: white;
    background: url("../assets/img/bg.jpg") no-repeat;
    background-color: #080808;
    overflow-x: hidden;
    .v-home__head {
      min-height: calc(85vh - 88px);
      .v-home__head-title {
        margin-top: 150px;
        font-size: 40px;
        font-weight: bold;
        position: relative;
        &::before {
          content: '';
          height: 100%;
          position: absolute;
          left: -10px;
          border: 1px solid #ffffff;
        }
        p {
          font-size:16px;
        }
        .v-home__head-img {
          position: absolute;
          left: 30%;
          top: 50%;
          img {
            width: 80%;
          }
        }
      }
    }
    .consult {
      display: flex;
      flex-direction: column;
      .consult__title {
        margin: 20px auto;
        display: block;
        background: #f0f403;
        width: 50%;
        color: #080808;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
      }
      .v-home__forms {
        width: 80%;
        margin: 0 auto;
        .v-home__forms-input {
          max-width: 20%;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        margin: 20px auto;
        justify-content: flex-end;
        position: relative;
        .btn-wrapper-err-mess {
          position: absolute;
          top: -12px;
          right: 150px;
        }
        .v-home__forms-btn {
          color: #080808;
        }
      }
    }
    .advokats {
      margin-top: 100px;
      padding: 100px 5%;
      position: relative;
      left: -5.5%;
      width: 100vw;
      background: url("../assets/img/law.jpg") center no-repeat;
      background-size: cover;
      .advokats__title {
        font-size: 30px;
        margin: 20px auto;
        display: block;
        text-align: center;
      }
      .advokats__wrapper {
        .advokats__container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          height: 150px;
          background: rgba(0,0,0, 0.9);
          border-radius: 5px;
          .advokats__title {
            margin-top: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .advokats__btn {
            border-top: 1px dashed gray;
          }
        }
      }
      .advokats__case {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0,0,0, 0.6);
        border-radius: 10px;
        margin: 50px 0;
        padding: 50px 0;
        font-size: 20px;
        .case-title {
          width: 100%;
          text-align: center;
          font-size: 40px;
        }
        .case-container {
          display: flex;
          align-items: center;
          img { margin-right: 20px; max-width: 120px }
        }
      }
    }
    .know {
      padding: 100px 5%;
      position: relative;
      left: -5.5%;
      width: 100vw;
      background: url("../assets/img/2.jpg") center no-repeat;
      background-size: cover;
      .know-wrap {
        background: rgba(0,0,0,0.5);
        padding: 20px;
        .know__title { font-size: 40px; margin: 20px auto; display: block; text-align: center }
        .know__wrapper {
          display: flex;
          justify-content: space-around;
          .know__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            .know__left-container {
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              margin: 20px;
              height: 130px;
              width: 300px;
              border-radius: 10px;
              border: 1px dashed white;
            }
          }
          .know__right {
            width: 35%;
            .know__right-btn {
              display: inherit;
              width: 200px;
              border: 1px solid white;
              margin-left: auto;
            }
          }
        }
      }
    }
    .popup {
      position: fixed;
      width: auto;
    }
  }
  @media (max-width: 720px) {
    .v-home .consult .consult__title { width: 100%!important; }
    .v-home__forms-wrap {
      flex-direction: column;
      .v-home__forms-input {
        max-width: 100%!important;
        width: 100%;
      }
    }
    .know__wrapper.d-flex {
      flex-wrap: wrap;
      .know__right {
        width: 100% !important;
        .v-home__forms-input {
          max-width: 100%!important;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .btn-wrapper {
      .btn-wrapper-err-mess {
        top: 52px !important;
        right: initial !important;
      }
    }
  }
</style>